import React from "react";
import "./projects.css";

const Projects = () => {
  return (
    <div>
      <h1>Under Constuction</h1>
      <img
        src="https://i.imgur.com/MSVqOqv.gif"
        alt="beaver chewing log in half from bojack horseman"
      />
    </div>
  );
};

export default Projects;
