import React from "react";

const About = () => {
  return (
    <div>
      <h2>Main content of the current page</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sed
        tincidunt urna. Donec sed ex lacus. Cras in turpis sem. Sed leo justo,
        imperdiet vel velit ac, placerat ultrices lacus. Donec et magna
        tincidunt, rhoncus nunc in, finibus purus. Morbi auctor congue ante sit
        amet cursus. In imperdiet semper justo sit amet tincidunt. Nunc vel
        scelerisque ante. Nulla facilisi. Mauris eu facilisis odio. In hac
        habitasse platea dictumst. Sed justo nisl, sagittis quis volutpat
        aliquam, gravida in tortor. Sed tempus justo nec tincidunt ullamcorper.
        Ut malesuada viverra ex et pellentesque. Cras commodo tortor sit amet
        sem vulputate, a bibendum mi fermentum. Sed porttitor vulputate ligula
        quis accumsan. Vivamus molestie tortor vel nisi blandit sodales id sit
        amet risus. Praesent congue posuere scelerisque. Nullam id quam
        placerat, dapibus elit eu, blandit ante. Integer pulvinar justo eget
        venenatis efficitur. Praesent in lobortis lectus. Sed faucibus maximus
        lacinia. Ut lacinia rhoncus felis, vel scelerisque erat malesuada id.
        Cras quis pellentesque risus. Nulla vitae gravida nibh. Cras iaculis
        dolor eu lacinia feugiat. Proin elementum pharetra fermentum. In
        consectetur justo ac ex eleifend interdum. Mauris convallis nunc id nibh
        bibendum condimentum. Nunc sit amet turpis tempor, dignissim lectus
        vitae, iaculis risus. Quisque tempus congue cursus. Donec a tempus
        nulla, vitae molestie augue. Duis efficitur libero nec pretium bibendum.
        Phasellus sit amet massa eget risus aliquam laoreet. Aliquam erat
        volutpat. Orci varius natoque penatibus et magnis dis parturient montes,
        nascetur ridiculus mus. Cras eget massa tempus, euismod nisl nec,
        pellentesque libero. Aenean posuere massa eget nibh congue, non gravida
        sapien sollicitudin. Donec urna urna, molestie non scelerisque ac,
        condimentum eu urna. Donec vel orci mi. Pellentesque suscipit tempus
        arcu sed fermentum. Class aptent taciti sociosqu ad litora torquent per
        conubia nostra, per inceptos himenaeos. Sed sed leo mi. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit. Cras vel tellus nibh. Etiam
        blandit erat ex, nec commodo metus imperdiet ut. Proin sed magna ornare,
        placerat ligula lobortis, euismod nisl. Praesent euismod aliquet magna
        vitae dapibus. Nunc eu justo sed mauris suscipit ultrices quis vitae
        neque. Vivamus bibendum tristique leo. Sed at metus ipsum. Maecenas
        congue magna eu lacinia gravida.
      </p>
    </div>
  );
};

export default About;
