import React from "react";
import { NavLink } from "react-router-dom";
import "./nav.css";

const Nav = () => {
  const links = [
    ["/about", "About"],
    ["/projects", "Projects"],
    ["/blog", "Blog"],
  ];

  const mappedLinks = links.map((link) => (
    <NavLink to={link[0]} className="nav-link" activeClassName="active">
      {link[1]}
    </NavLink>
  ));

  return <aside className="nav">{mappedLinks}</aside>;
};

export default Nav;
