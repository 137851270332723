import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import About from "./about/about";
import "./App.css";
import Blog from "./blog/blog";
import Header from "./header/header";
import Nav from "./nav/nav";
import Projects from "./projects/projects";

function App() {
  return (
    <Router>
      <main className="main bluegray">
        <Header />
        <section className="page-content">
          <Nav />
          <Switch>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/projects">
              <Projects />
            </Route>
            <Route exact path="/blog">
              <Blog />
            </Route>
            <Redirect exact to="/about" />
          </Switch>
        </section>
      </main>
    </Router>
  );
}

export default App;
