import React from "react";
import "./header.css";
import pic from "./pic.jpeg";

const Header = () => {
  return (
    <header className="header">
      <img src={pic} alt="Me from my linkedin" className="header-photo" />
      <h1>Joe Stone</h1>
    </header>
  );
};

export default Header;
